#vision-mission h2 {
  color: #3fbbc0;
  text-align: center;
  overflow: hidden;
}
#vision-mission {
  position: relative;
  /* border: 2px solid red; */
  overflow: hidden;
}
#vision-mission #vision-float-div {
  height: 400px;
  width: 25%;
  border: 22px solid #3fbbc0;
  border-radius: 60px;
  position: absolute;
  top: -10%;
  left: -23%;
  transform: rotate(45deg);
}
#vision-mission #mission-float-div {
  height: 400px;
  width: 25%;
  border: 22px solid #3fbbc0;
  border-radius: 60px;
  position: absolute;
  bottom: -8%;
  right: -23%;
  transform: rotate(45deg);
}
.vission-mission-card #vm-card1,
#vm-card2,
#vm-card3 {
  /* height: 300px; */
  max-width: 250px;
  /* border: 2px solid red; */
}
#vision-mission .circle-icon {
  background-color: #fff;
  /* border: 2px solid red; */
  height: 120px;
  width: 120px;
  padding: 23px 10px 10px 10px;
  box-shadow: 0px 3px 2px 2px rgb(179, 177, 177);
  margin-left: 20%;
  margin-right: 20%;
}
#vision-mission .circle-icon .iconify {
  color: #3fbbc0;
  font-size: 80px;
}
#vm-card3 li {
  list-style-type: none;
}
.ft-darkks {
  text-align: center;
}
