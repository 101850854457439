.content #iframe {
  border-radius: 20px;
  width: 100%;
  height: 400px;
  /* border: 8px solid #3fbbc0; */
  /* position: relative; */
  margin-bottom: 100px;
}
#short-about #inner {
  height: 400px;
  width: 35%;
  border: 25px solid #3fbbc0;
  border-radius: 60px;
  position: absolute;
  top: 20%;
  left: 2%;
}
#short-about {
  position: relative;
}
.content #list-img img {
  width: 100%;
  max-height: 500px;
}

.content #know-more {
  border-radius: 50px;
  background-color: #666666;
  color: #fff;
  border: 4px solid #3fbbc0;
}
#shortAbout-li li {
  list-style: none;
  margin-bottom: 2px;
}
#shortAbout-li .iconify {
  color: #3fbbc0;
  font-size: 20px;
}
.content #about-megavent h1 {
  text-align: center;
  font-weight: bolder;
  overflow: hidden;
}
.content #about-megavent {
  border-right: 8px solid #3fbbc0;
  border-radius: 80px;
  padding-right: 20px;
  padding-top: 20px;
}
@media only screen and (max-width: 400px) {
  .content #iframe {
    width: 100%;
    height: 200px;
    border: 8px solid #3fbbc0;
    margin-bottom: 10px !important;
  }
  #short-about #inner {
    display: none;
  }
}
@media only screen and (max-width: 780px) {
  .content #iframe {
    border: 8px solid #3fbbc0;
    margin-bottom: 10px !important;
  }
  #short-about #inner {
    display: none;
  }
}
@media only screen and (max-width: 300px) {
  .content #about-megavent {
    padding-top: 20px;
  }
}
@media only screen and (max-width: 1030px) {
  #short-about #inner {
    width: 30%;
    left: -60px;
  }
}
/* ul li::before {
        content: "\2713";
        color: #3fbbc0;
        font-weight: bold;
        display: inline-block; 
        width: 1em;
        margin-left: -1em;
    } */
/* <span className="iconify" data-icon="akar-icons:double-check" data-inline="false"></span> */
#about-megavent {
  text-align: justify;
}
