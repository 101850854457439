/*! Universal CSS */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
#preloader {
  background: #fff url(../img/preloader/preloadermegavent.gif) no-repeat center
    center;
  background-size: 10%;
  height: 100vh;
  width: 100%;
  position: fixed;
  z-index: 100;
}
.navbar-brand img {
  width: 110px !important;
}
#full-Header-Contact span:nth-child(1)::after,
#full-Header-Contact span:nth-child(2)::after {
  content: "|";
  margin: 0 5px;
  color: blue;
  font-weight: bolder;
}
#full-Header-Contact span:nth-child(2)::after {
  font-weight: normal;
}
nav {
  background-color: rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 3px -1px rgba(124, 124, 124, 0.9);
  font-size: 18px;
  letter-spacing: 2px !important;
}
/* .navbar-light .navbar-nav .nav-link  */
#navbarSupportedContent a {
  color: #fff;
  font-size: 16px;
  text-decoration: none;
}
@media only screen and (max-width: 768px) {
  #navbarSupportedContent a {
    font-size: 13px;
    padding-left: 8px;
  }
}
.dropdown-item {
  width: auto !important;
  background-color: #666666;
}
.dropdown-item:hover {
  background-color: #3fbbc0;
  color: #fff;
  font-weight: bold;
}
.dropdown-menu {
  background-color: #666666;
}
#carousel-banner {
  /* background-color: #3fbbc0; */
  height: 90.5vh;
  /* clip-path: polygon(0% 0%, 100% 0%, 100% 95%, 50% 100%, 0% 95%); */
}
/*! Home Page banner image CSS */
#carousel-banner img {
  width: 100%;
  height: 93vh;
  /* z-index: 9999; */
  /* clip-path: polygon(0% 0%, 100% 0%, 100% 95%, 50% 100%, 0% 95%); */
}
/* ! Carousel item CSS */
#carousel-banner .carousel-item {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
/* ! Home Page carousel text CSS */
#carousel-banner .carousel-caption {
  display: none;
}
/*! Media Query for Banner on small scrren CSS */
@media only screen and (max-width: 768px) {
  #carousel-banner img {
    width: 100%;
    height: 40vh;
  }
  #carousel-banner {
    height: 41.5vh;
  }
  #kindlySkewMe,
  #full-Header-Contact,
  #kindlySkewMe {
    display: none;
  }
}
.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: none;
}
#kindlySkewMe .card {
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: -480px;
  color: #fff;
  height: 250px;
  left: 10%;
  padding-right: 20px;
}
#kindlySkewMe .helper-text {
  padding-left: 20px;
}
#kindlySkewMe h1 {
  font-size: 55px;
}
#bolderme {
  font-size: 85px;
}
#kindlySkewMe p {
  font-size: 22px;
}
#kindlySkewMe button {
  font-size: 24px;
  background-color: #666666;
  color: #fff;
  font-weight: 600;
  border: 4px solid #3fbbc0;
}
#kindlySkewMe .helper a::before {
  content: "";
  width: 7px;
  height: 40px;
  background-color: #3fbbc0;
  position: absolute;
  border-radius: 5px;
  top: -100px;
}
#kindlySkewMe .helper a {
  display: block;
  position: relative;
  top: -140px;
  left: -22px;
  color: #fff;
  font-weight: bolder;
  font-size: 20px;
}
#kindlySkewMe .helper a::after {
  content: "";
  width: 7px;
  height: 40px;
  background-color: #3fbbc0;
  position: absolute;
  border-radius: 5px;
  bottom: -105px;
  left: 0;
}
#kindlySkewMe .helper a i {
  margin-left: -5px;
}
.bg-darkk {
  background-color: #666666 !important;
}
.ft-darkk {
  color: #666666 !important;
}
#themeOwl {
  background-image: url(../img/banner/banner6.jpg),
    linear-gradient(#e6e5e579, #f3f1f156);
  background-attachment: fixed;
  background-position: center;
  padding: 100px 0;
  background-repeat: no-repeat;
  background-size: cover;
}
#themeOwl h1 {
  font-size: 50px;
  color: #fff;
}
#themeOwl h6 {
  font-size: 20px;
}
#themeOwl .card {
  border-radius: 30px;
  height: 300px;
}
#themeOwl .card img {
  border-radius: 15px;
  height: 150px;
}
.card-heading-h6 {
  height: 50px;
  border-bottom: 1px solid #3fbbc0;
  border-radius: 30px;
}
#themeOwl .iconify {
  color: #3fbbc0;
}

#having-someCard .card {
  background-color: #3fbbc0;
  color: #fff;
}
#tagline {
  font-size: 12px !important;
}
#nav img {
  width: 70px;
}
.text-til {
  color: #3fbbc0;
}
.product-title-upper {
  text-transform: uppercase !important;
}
.bg-til {
  background-color: #3fbbc0;
}

.nav-tab {
  box-shadow: none;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #fff;
  background-color: #3fbbc0;
}
.nav-tabs a {
  color: #666666;
}
.nav-tabs {
  border-bottom: 1px solid #3fbbc0;
}
.banner-heading-gradient {
  padding-top: 8%;
  padding-bottom: 2%;
  background: linear-gradient(90deg, #3fbbc0 1%, #73d8db 100%);
}
.banner-heading-gradient a {
  color: #fff;
}
#full-Header-Contact a {
  color: #666666;
}

/* ----------nav---------- */

.dropdown-menu.show {
  margin-left: -70px;
}
