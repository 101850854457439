/* #footer-mainDiv {
  background-image: url(./asset//img//banner/banner1.jpg);
} */
#footer-content a {
  text-decoration: none;
  color: #eee;
  font-size: 15px;
}
#footer-content a:hover {
  color: #3fbbc0;
}
#footer-content li {
  margin-bottom: 10px;
  list-style: none;
}
#footer-content .iconify {
  font-size: 30px;
}
#footer-column2 .iconify {
  color: #3fbbc0;
  font-size: 20px;
}
#footer-column3 .iconify {
  color: #3fbbc0;
  font-size: 20px;
}
#footer-content h4,
h6 {
  color: #3fbbc0;
}
#hr-line {
  border-top: 2px solid #3fbbc0;
}
