.products-heading {
  /* margin-top: 120px; */
  margin-bottom: 60px;
  position: relative;
}

.products-heading h1 {
  color: #3fbbc0;
  overflow: hidden;
  font-size: 50px;
}

#products-container-fluid #products-float-div-left {
  height: 300px;
  width: 20%;
  border: 22px solid #3fbbc0;
  border-radius: 60px;
  position: absolute;
  top: 0%;
  left: -16%;
  transform: rotate(45deg);
}
#products-container-fluid #products-float-div-right {
  height: 300px;
  width: 20%;
  border: 22px solid #3fbbc0;
  border-radius: 60px;
  position: absolute;
  top: 85%;
  right: -16%;
  transform: rotate(45deg);
}
#products-container-fluid {
  position: relative;
  overflow: hidden;
}
#products-container-fluid #products-float-div-down {
  height: 300px;
  width: 20%;
  border: 22px solid #3fbbc0;
  border-radius: 60px;
  position: absolute;
  bottom: -3%;
  right: -15%;
  transform: rotate(45deg);
}


#products-container-fluid .card p {
  text-align: justify;
  
  font-size: 18px;
} 
#having-card img{
  width: 100%;
height: auto;
  border-radius: 20px;
margin-top:-10px;
transition: .5s;
}
#having-card{
  padding:0px;
}
#products-know-more a{
  text-decoration: none;
  color:#fff;
}
#products-know-more{
  border-radius: 50px;
  background-color: #666666;
  color: #fff;
  border: 3px solid #3fbbc0;
}
#products-container-fluid .need-border{
  border-left: 12px solid #3fbbc0;
 
  border-right: 12px solid #3fbbc0;
  border-radius: 50px;
}
#products-container-fluid .card{
  border-radius: 20px;
  border: none;
  transition: .5s;
}
#products-container-fluid .card:hover{
  box-shadow:none !important;

}
#products-container-fluid .card:hover img{
  box-shadow:none !important;
  
  margin-top:5px;
}
@media only screen and (max-width:520px){
  #products-container-fluid .need-border{
    border-left: 8px solid #3fbbc0;
    border-right: 8px solid #3fbbc0;
    border-radius: 50px;
  }
}