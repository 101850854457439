.box{
    font-family: 'Poppins', sans-serif;
    text-align: center;
    overflow: hidden;
    position: relative;
    border:4px solid #3fbbc0;
    border-radius: 15px;
}
.box:before,
.box:after{
    content: "";
    background: #333;
    transform: scale(0);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: all 0.5s ease 0s;
}
.box:after{
    background: #000;
    border: 1px solid #aaa;
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 10px;
    transition: all 0.5s ease 0.2s;
}
.box:hover:before{
    opacity: 0.5;
    transform: scale(1);
}
.box:hover:after{
    opacity: 0.35;
    transform: scale(1);
}
.box img{
    width: 100%;
    height: 210px;
}
.box .box-content{
    color: #fff;
    width: 85%;
    filter: blur(5px);
    opacity: 0;
    transform: translateX(-50%) translateY(-50%);
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    transition: all 0.4s ease 0.3s;
}
.box:hover .box-content{
    filter: blur(0);
    opacity: 1;
}
.box .title{
    font-size: 25px;
    font-weight: 200;
    text-transform: capitalize;
    margin: 0 0 1px;
}
.box .post{
    font-size: 15px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: block;
    margin: 0 0 10px;
}
.box .icon{
    padding: 0;
    margin: 0;
    list-style: none;
}
.box .icon li{
    margin: 0 3px;
    display: inline-block;
}
.box .icon li a{
    color: #fff;
    font-size: 14px;
    line-height: 24px;
    height: 27px;
    width: 27px;
    border: 1px solid rgba(255,255,255,0.5);
    box-shadow: 5px 5px 0 rgba(0, 0, 0, 0.7);
    display: block;
    transition: all 0.3s ease 0s;
}
.box .icon li a:hover{
    color: #fff;
    background-color: #000;
}
@media only screen and (max-width:990px){
    .box{ margin: 0 0 30px; }
}

/* -----------extra----------- */
#our-projects-owl h1{
    color: #3fbbc0;
}
#themeOwl a{
  text-decoration: none;
}