#mySidenav a {
    position: fixed;
    right: -193px;
    transition:all 0.4s linear;
    padding: 10px 15px;
    width: 240px;
    text-decoration: none;
    font-size: 20px;
    color: #3fbbc0;
    border-radius: 5px;
    z-index:999;
   border: 2px groove #3fbbc0;
 
  }
  #mySidenav a:hover {
    right: -10px;
    color: #666666;
  }
  #call {
    top: 240px;
    background-color: #fff ;
  } 
  #bookapp{
    top: 295px;
    background-color: #fff ;
  }
  .fa-whatsapp{
      font-size: 25px;
  }