#aboutus-container-fluid {
  background-color: rgb(243, 241, 240);
}
.short-aboutus #short-aboutus-left {
  background-color: #fff;
  border-radius: 20px;
}
#aboutus-container-fluid #short-about-img {
  margin-left: -3%;
}
#short-about-img img {
  width: 100%;
  height: auto;
  border-radius: 10px 80px 10px 10px;
}

#aboutus-container-fluid #down-cat {
  border-radius: 50px;
  background-color: #666666;
  color: #fff;
  border: 4px solid #3fbbc0;
  font-size: large;
}
#aboutus-container-fluid {
  position: relative;
  overflow: hidden;
}
#about-float-div {
  height: 400px;
  width: 25%;
  border: 22px solid #3fbbc0;
  border-radius: 60px;
  position: absolute;
  top: -30%;
  right: -22%;
  transform: rotate(45deg);
}
#short-aboutus-left p {
  text-align: justify;
}
#short-aboutus-left h2 {
  color: #3fbbc0;
}
.costomeImage {
  height: 400px !important;
}
