

 .Single-product #si-pro-enquire{
    border-radius: 50px;
    background-color: #666666;
    color: #fff;
    border: 4px solid #3fbbc0;
      
    }
    .Single-product  #si-pro-heading h1{
        color: #3fbbc0;
        font-weight:bolder;
        text-align: center;

    }
    #si-pro-right-content{
        border-right: 8px solid #3fbbc0;
        border-radius: 80px;
        padding-left: 30px;
    }
    #si-pro-down-left-content{
        border-left: 8px solid #3fbbc0;
        border-radius: 80px;
        padding-left: 20px;
        padding-top:30px;
    }
    #si-pro-right-content li{
        list-style-type: none;
    }
    #si-pro-right-content .iconify{
        color: #3fbbc0;
    }
 #si-pro-left-img{
     padding-left: 0px;
     padding-right: 0px;
      /* border: 2px solid red; */
    
    }
    #si-pro-left-img img{
        width:100%;
        height: 500px;
        border-left: 12px solid #3fbbc0;
        border-bottom: 12px solid #3fbbc0;
        border-radius: 12px;
       
      }
      #si-pro-left-img #iframe, #si-pro-down-left-vid #iframe{
        border-radius: 20px;
        width:100%;
        height:400px;
        /* border: 8px solid #3fbbc0; */
        /* position: relative; */
        margin-bottom: 100px;
    }
      #si-pro-down-left-content p{
          text-align: justify;
      }

      /* ---------------------card hover-------------- */
    #si-pro-dwon-pro-card .box{
        font-family: 'Poppins', sans-serif;
        text-align: center;
        overflow: hidden;
        position: relative;
        border:4px solid #3fbbc0;
        border-radius: 15px;
    }
     #si-pro-dwon-pro-card .box:before,
    .box:after{
        content: "";
        background: #333;
        transform: scale(0);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        transition: all 0.5s ease 0s;
    }
     #si-pro-dwon-pro-card .box:after{
        background: #000;
        border: 1px solid #aaa;
        top: 10px;
        left: 10px;
        right: 10px;
        bottom: 10px;
        transition: all 0.5s ease 0.2s;
    }
     #si-pro-dwon-pro-card .box:hover:before{
        opacity: 0.5;
        transform: scale(1);
    }
     #si-pro-dwon-pro-card .box:hover:after{
        opacity: 0.35;
        transform: scale(1);
    }
    #si-pro-dwon-pro-card .box{
        padding-left: 0px;
        padding-right: 0px;
        max-width: 250px;
    }
     #si-pro-dwon-pro-card .box img{
        width: 100%;
        height: 220px;
    }
     #si-pro-dwon-pro-card .box .box-content{
        color: #fff;
        width: 85%;
        filter: blur(5px);
        opacity: 0;
        transform: translateX(-50%) translateY(-50%);
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 2;
        transition: all 0.4s ease 0.3s;
    }
     #si-pro-dwon-pro-card .box:hover .box-content{
        filter: blur(0);
        opacity: 1;
    }
     #si-pro-dwon-pro-card .box .title{
        font-size: 25px;
        font-weight: 200;
        text-transform: capitalize;
        margin: 0 0 1px;
    }
     #si-pro-dwon-pro-card .box .post{
        font-size: 15px;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 1px;
        display: block;
        margin: 0 0 10px;
    }
     #si-pro-dwon-pro-card .box .icon{
        padding: 0;
        margin: 0;
        list-style: none;
    }
     #si-pro-dwon-pro-card .box .icon li{
        margin: 0 3px;
        display: inline-block;
    }
     #si-pro-dwon-pro-card .box .icon li a{
        color: #fff;
        font-size: 14px;
        line-height: 24px;
        height: 27px;
        width: 27px;
        border: 1px solid rgba(255,255,255,0.5);
        box-shadow: 5px 5px 0 rgba(0, 0, 0, 0.7);
        display: block;
        transition: all 0.3s ease 0s;
    }
     #si-pro-dwon-pro-card .box .icon li a:hover{
        color: #fff;
        background-color: #000;
    }
    @media only screen and (max-width:990px){
        #si-pro-dwon-pro-card .box{ margin: 0 0 30px; }
    }
    @media only screen and (max-width:545px) and (min-width:400px){
        #si-pro-dwon-pro-card .box{ margin-left:22% !important; }
    }
    @media only screen and (max-width:400px) and (min-width:300px){
        #si-pro-dwon-pro-card .box{ margin-left:10% !important; }
    }



    #si-pro-dwon-pro-card-heading{
border-bottom: 4px solid #3fbbc0;
    }
    #si-pro-dwon-pro-card-heading h2{
        color: #3fbbc0;        
    }
    .Single-product{
        position: relative;
        overflow: hidden;
        
    }
    .Single-product #si-pro-float-div{
        height: 400px;
        width: 25%;
        border:22px solid #3fbbc0 ;
        border-radius: 60px;
        position: absolute;
        bottom: 10%;
        right: -22%;
        transform: rotate(45deg);
    }
     #si-pro-down-float-div{
    

    height: 400px;
    width: 25%;
    border:22px solid #3fbbc0 ;
    border-radius: 60px;
    position: absolute;
    top: -2%;
    left: -22%;
    transform: rotate(45deg);
    }

    @media only screen and (max-width: 400px) {
        #si-pro-left-img #iframe, #si-pro-down-left-vid #iframe {
            width: 100%;
            height: 200px;
            border: 8px solid #3fbbc0;
            margin-bottom: 10px !important;
          }
         
      }
      @media only screen and (max-width: 780px) {
        #si-pro-left-img #iframe, #si-pro-down-left-vid #iframe {
           
        
            margin-bottom: 10px !important;
          }
          
   
     
    
      }