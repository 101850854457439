#testimonial-home h1 {
  color: #3fbbc0;
  text-align: center;
  overflow: hidden;
}
#testimonial-owl .card {
  padding: 0 10px;
  box-shadow: 3px 4px 4px 0px rgba(216, 216, 216, 0.65);
  margin: 5px 15px;
  height: 400px;
}
#testimonial-owl .card p {
  text-align: justify;
}
#testimonial-owl .card .card-icon {
  margin-bottom: 3%;
  font-size: 65px;

}
#testimonial-owl .card .card-icon .iconify {
  padding: 10px;
  border: 3px solid rgba(216, 216, 216, 0.65);
  border-radius: 50%;
 color: #3fbbc0;
 
}
.card-icon2 {
  position: relative;
  top: -30px;
}
.card-icon2 i {
  font-size: 56px;
}

#testimonial-home {
  position: relative;
}
#testimonial-home #testimonial-home-float-div{
  height: 400px;
  width: 25%;
  border:22px solid #3fbbc0 ;
  border-radius: 60px;
  position: absolute;
  top: -14%;
  left: -22.2%;
  transform: rotate(45deg);
}
