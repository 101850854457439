#our-blogs .serviceBox{
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    padding: 60px 0 30px;
    position: relative;
}
#our-blogs .serviceBox:after{
    content: '';
    background: linear-gradient(#4becf1,#3fbbc0);
    height: 40px;
    width: 40px;
    transform: translateX(-50%) rotate(45deg);
    position: absolute;
    left: 50%;
    bottom: 10px;
}
#our-blogs .serviceBox .service-content{
    background: #fff;
    padding: 80px 15px 0;
    position: relative;
    z-index: 1;
    transition: all 0.3s ease;
}
#our-blogs .serviceBox .service-content:hover{ box-shadow: 5px 5px 2px rgba(0,0,0,0.1); }
#our-blogs .serviceBox .service-content:before{
    content: "";
    background: linear-gradient(#4becf1,#3fbbc0);
    width: 100%;
    height: 63px;
    border-radius: 0 0 80px 0;
    position: absolute;
    top: 0;
    left: 0;
}
#our-blogs .serviceBox .service-icon{
    color: #3fbbc0;
    background: linear-gradient(#3fbbc0,#4becf1);
    font-size: 50px;
    text-align: center;
    line-height: 120px;
    height: 120px;
    width: 120px;
    border-radius: 50%;
    box-shadow: 5px 0 10px rgba(0,0,0,0.2);
    position: absolute;
    right: 0;
    top: -60px;
    z-index: 1;
}
#our-blogs .serviceBox .service-icon:after{
    content: '';
    background: #fff;
    width: 80%;
    height: 80%;
    border-radius: 50% 50%;
    box-shadow: 0 0 15px rgba(0,0,0,0.5);
    transform: translateX(-50%) translateY(-50%);
    position: absolute;
    top: 50%;
    left: 50%;
    z-index:-1;
}
#our-blogs .serviceBox .title{
    color: #3fbbc0;
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0 0 10px;
}
#our-blogs .serviceBox .description{
    color: #777;
    font-size: 14px;
    line-height: 25px;
}
#our-blogs .serviceBox .read-more{
    color: #777;
    background-color: #fff;
    font-size: 13px;
    text-transform: capitalize;
    padding: 4px 8px;
    border-radius: 10px;
    box-shadow: 0 0 5px rgba(0,0,0,0.3);
    display: inline-block;
    transform: translateY(12px);
    transition: all 0.3 ease;
}
#our-blogs .serviceBox .read-more:hover{ color: #3fbbc0; }
/* .serviceBox.green:after,
.serviceBox.green .service-content:before{
    background: linear-gradient(#D5DB00,#7ABA48);
}
.serviceBox.green .service-icon{
    color: #7ABA48;
    background: linear-gradient(#7ABA48,#D5DB00);
}
.serviceBox.green .title,
.serviceBox.green .read-more:hover{
    color: #7ABA48;
}
.serviceBox.blue:after,
.serviceBox.blue .service-content:before{
    background: linear-gradient(#39C5E0,#2642C9);
}
.serviceBox.blue .service-icon{
    color: #2642C9;
    background: linear-gradient(#2642C9,#39C5E0);
}
.serviceBox.blue .title,
.serviceBox.blue .read-more:hover{
    color: #2642C9;
}
.serviceBox.purple:after,
.serviceBox.purple .service-content:before{
    background: linear-gradient(#A23592,#5B2F6A);
}
.serviceBox.purple .service-icon{
    color: #5B2F6A;
    background: linear-gradient(#5B2F6A,#A23592);
}
.serviceBox.purple .title,
.serviceBox.purple .read-more:hover{
    color: #5B2F6A;
} */
@media only screen and (max-width:990px){
    #our-blogs .serviceBox{ margin: 0 0 30px; }
}

#our-blogs h1{
    color:  #3fbbc0;
    }
.bg{
    background-color: rgb(236, 235, 235);
}