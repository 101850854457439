#project #project-iframe {
    border-radius: 20px;
    width: 100%;
    height: 400px;
    border: 8px solid #3fbbc0;
    /* position: relative; */
    margin-bottom: 100px;
  }
  #project img {
    width: 100%;
    height: 180px;
  }
  #project .heading h1 {
    color: #3fbbc0;
    font-size: 40px;
  }
  #project .heading p {
    font-size: 22px;
    letter-spacing: 1px;
  }
  #project #content-having{
      border-left: 8px solid#3fbbc0;
      border-radius: 60px;
      padding-left:15px ;
  }
  #project #content-having h3 {
    color: #3fbbc0;
    font-size: 30px;
  }
  #project #content-having p,
  #body-text p {
    color: rgb(100, 100, 100);
    text-align: justify;
    font-size: 20px;
  }
  #project-main-div{
    position: relative;
    overflow: hidden;
  }
  #project-float-div {
    height: 400px;
    width: 25%;
    border: 22px solid #3fbbc0;
    border-radius: 60px;
    position: absolute;
    bottom: 10%;
    right: -22%;
    transform: rotate(45deg);
  }
  #project-down-float-div {
    height: 400px;
    width: 25%;
    border: 22px solid #3fbbc0;
    border-radius: 60px;
    position: absolute;
    top: -2%;
    left: -22%;
    transform: rotate(45deg);
  }