.management-container-fluid {
  background-color: rgb(243, 241, 240);
}
.management-aboutus #management-aboutus-right {
  background-color: #fff;
  border-radius: 20px;
  margin-left: -3%;
}
#management-about-img {
  margin-top: 4%;
}
#management-about-img img {
  height: auto;
  width: 100%;
  border-radius: 80px 10px 10px 10px;
}
#management-aboutus-right p {
  text-align: justify;
}
#management-aboutus-right h1{
  color: #3fbbc0;
}
