#contact-page h1{
color:  #3fbbc0;
}
#contact-page input, textarea{
    outline: none !important;
    }
#contact-page i{
        color:  #3fbbc0;
        font-size: 20px;
        margin-right: 10px;
        }

#have-map iframe{
    height: 450px;
    width: 100%;
    border: 4px solid  #3fbbc0;
}
#contact-page{
    padding: 0px 0px;
    
}
#contact-page button{
    background-color:  #3fbbc0;
    color: #fff;
}