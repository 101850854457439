.product-heading {
  /* margin-top: 120px; */
  margin-bottom: 60px;
  position: relative;
}
.product-cards {
  margin-bottom: 120px;
}
.product-heading h1 {
  color: #3fbbc0;
  overflow: hidden;
}
.product-cards .p-card img {
  width: 100%;
  height: auto;
}
.product-cards .p-card {
  background-color: #fff;
  border-radius: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-bottom: 10px;
}
.product-cards .p-card p,
li {
  font-size: 15px;
}
.product-cards .p-card .yellow {
  color: #3fbbc0;
}
.product-cards .p-card ul {
  list-style: none;
}
.Single-product p {
  font-size: 17px;
}
.Single-product #si-pro-enquire {
  border-radius: 50px;
  background-color: #666666;
  color: #fff;
  border: 4px solid #3fbbc0;
}
.Single-product #si-pro-heading h1 {
  color: #3fbbc0;
  font-weight: bolder;
  text-align: center;
}
#si-pro-right-content {
  border-right: 8px solid #3fbbc0;
  border-radius: 80px;
  padding-left: 40px;
  padding-top: 60px;
  padding-bottom: 60px;
}
#si-pro-right-content a {
  text-decoration: none;
  color: #666666;
}
#si-pro-right-content a:hover {
  color: #3fbbc0;
}
#si-pro-right-content ol li {
  list-style: none;
  font-size: 18px;
  margin-left: 10px;
  line-height: 30px;
}
#si-pro-down-left-content ul li {
  list-style: none !important;
  font-size: 17px;
  margin: 0 10px;
  line-height: 30px;
  text-align: justify;
}
/* #si-pro-down-left-content ul li::before {
  content: "\27A5";
  color: #3fbbc0;
} */
#si-pro-down-left-content {
  border-left: 8px solid #3fbbc0;
  border-radius: 80px;
  padding-left: 20px;
  padding-top: 30px;
}
#si-pro-right-content li {
  list-style-type: none;
}
#si-pro-right-content .iconify {
  color: #3fbbc0;
}
#si-pro-left-img {
  padding-left: 0px;
  padding-right: 0px;
  /* border: 2px solid red; */
}
#si-pro-left-img img {
  width: 100%;
  height: 500px;
  border-left: 12px solid #3fbbc0;
  border-bottom: 12px solid #3fbbc0;
  border-radius: 12px;
}
#si-pro-left-img #iframe {
  border-radius: 20px;
  width: 100%;
  height: 400px;
  border: 8px solid #3fbbc0;
  /* position: relative; */
  margin-bottom: 100px;
}
#si-pro-down-left-content p {
  text-align: justify;
}

.Single-product {
  position: relative;
  overflow: hidden;
}
.Single-product #si-pro-float-div {
  height: 400px;
  width: 25%;
  border: 22px solid #3fbbc0;
  border-radius: 60px;
  position: absolute;
  bottom: 10%;
  right: -22%;
  transform: rotate(45deg);
}
#si-pro-down-float-div {
  height: 400px;
  width: 25%;
  border: 22px solid #3fbbc0;
  border-radius: 60px;
  position: absolute;
  top: -2%;
  left: -22%;
  transform: rotate(45deg);
}

@media only screen and (max-width: 400px) {
  #si-pro-left-img #iframe,
  #si-pro-down-left-vid #iframe {
    width: 100%;
    height: 200px;
    border: 8px solid #3fbbc0;
    margin-bottom: 10px !important;
  }
}
@media only screen and (max-width: 780px) {
  #si-pro-left-img #iframe #iframe {
    margin-bottom: 10px !important;
  }
}

.product-cards .p-card ul li::before {
  content: "\2022";
  color: #3fbbc0;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}
.product-cards .p-card #product-know-more {
  border-radius: 50px;
  background-color: #666666;
  color: #fff;
  border: 3px solid #3fbbc0;
  /* font-size:small; */
}
.product-heading #product-float-div {
  height: 300px;
  width: 20%;
  border: 22px solid #3fbbc0;
  border-radius: 60px;
  position: absolute;
  top: -80%;
  left: -16%;
  transform: rotate(45deg);
}
#product-container-fluid {
  position: relative;
  overflow: hidden;
  margin-top: -40px;
}
#product-container-fluid #product-float-div-down {
  height: 300px;
  width: 20%;
  border: 22px solid #3fbbc0;
  border-radius: 60px;
  position: absolute;
  bottom: -3%;
  right: -15%;
  transform: rotate(45deg);
}
#product-type img {
  width: 100%;
  height: 250px;
  margin-bottom: 10px;
  border-top-left-radius: 30px;
  border-bottom-right-radius: 30px;
}
#product-type iframe {
  border-radius: 20px;
  width: 100%;
  height: 240px;
  border: 4px solid #3fbbc0;
  margin-bottom: 10px;
}

/* ---------owl---------------- */
#my-owl .boxes,
#my-owl img {
  border-top-left-radius: 40px;
  border-bottom-right-radius: 40px;
  height: 250px;
  width: 250px;
}
#my-owl img {
  border: 3px solid #3fbbc0;
}
.umbrella img {
  height: auto;
  width: 100%;
}
.ft-darkk {
  text-align: justify;
}
