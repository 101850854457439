.events-img img{
    width: 100%;
    height: 280px;
    border-radius: 30px;

  }
  .events-container{
    position: relative;
    border-bottom: 2px solid rgb(0, 225, 255);
    border-radius: 30px;
  }

  .events-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color:rgba(0, 225, 255, 0.2) ;
    overflow: hidden;
    width: 0;
    height: 100%;
    transition: .5s ease;
    border-radius: 30px;
 
  }
  .events-text {
    color: white;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    white-space: nowrap;
  }
  
  .events-img:hover .events-overlay {
    width: 100%;
  }