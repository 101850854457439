.projects-main-page  #projects-heading h1{
    color: #3fbbc0;
    font-weight:bolder;
    text-align: center;

}
/* .projects-cards .cards{
    width: 20rem;
    
} */
.projects-cards .cards img{
    width: 100%;
    height: 300px;
}
.projects-cards .cards .cards-body{
    background-color: #3fbbc0;
    color: #fff;
    text-align: center;
}
.projects-main-page{
    position: relative;
    overflow: hidden;
}
.projects-main-page  #projects-upper-float-div{
    height: 300px;
    width: 20%;
    border:22px solid #3fbbc0 ;
    border-radius: 60px;
    position: absolute;
    top: -1%;
    right: -16%;
    transform: rotate(45deg);
}
.projects-main-page  #projects-lower-float-div{
    height: 300px;
    width: 20%;
    border:22px solid #3fbbc0 ;
    border-radius: 60px;
    position: absolute;
    bottom: -1%;
    left: -16%;
    transform: rotate(45deg);
    
}
.projects-main-page a{
    text-decoration: none !important;
}
