#faq-home {
    background-color: rgb(243, 241, 240);
}

#faq-home h1{
    color: #3fbbc0;
    text-align: center;
    overflow: hidden;  
}
/* #faq-home-cards #faq-home-card1, #faq-home-card2, #faq-home-card3{
    border: 1px solid red;
    
} */
#faq-home-cards li{
    list-style-type:none;
    line-height: 2rem;
    /* color: rgb(77, 76, 76) !important; */
}
.faq-link{
    color:rgb(77, 76, 76);
}

#faq-home-cards h5{
    overflow: hidden;
}
 .div-strip{
    background-color: #3fbbc0;
}
#faq-home{
    position: relative;
    overflow: hidden;
    /* border: 2px solid red; */
    /* overflow: hidden; */
}
#faq-home #faq-home-float-div{
    height: 400px;
    width: 25%;
    border:22px solid #3fbbc0 ;
    border-radius: 60px;
    position: absolute;
    top: -14%;
  right: -22.2%;
    transform: rotate(45deg);
}
.div-strip button{
    background-color: rgba(250, 187, 50, 0);
    border: none;
    color: #fff;
}
.div-strip{
    color: #fff;
}
.div-strip .iconify{
    font-size: 20px;
}
#faq-read-more a{
    background-color: #666666;
    color: #fff;
    border: 3px solid #3fbbc0;
}