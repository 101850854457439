#quality-aboutus h1 {
  color: #3fbbc0;
  text-align: center;
  overflow: hidden;
}
.quality-content {
  background-color: #3fbbc0;
}
#quality-back-img {
  /* background-image: url(../asset/img/banner/banner1.jpg); */
  background-size: cover;
  background-repeat: no-repeat;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.quality-content .quality-content-cards {
  border: 8px solid #fff;
  border-radius: 10px;
}
.quality-content .quality-content-cards .quality-content {
  max-width: 250px;
  color: #fff;
}
.quality-content-cards img {
  width: 100px;
  border: 4px solid #fff;
  border-radius: 50%;
}
#quality-aboutus {
  position: relative;
  overflow: hidden;
}
#quality-aboutus #quality-float-div {
  height: 380px;
  width: 380px;
  border: 22px solid #3fbbc0;
  border-radius: 60px;
  position: absolute;
  top: -15%;
  left: -23%;
  transform: rotate(50deg);
}
#quality-sys {
  border: none;
}
#quality-sys img {
  max-width: 400px;
  max-height: 500px;
  border-radius: 20px;
}
@media only screen and (max-width: 600px) {
  #quality-head {
    font-size: 35px;
  }
}
